.App {
  box-sizing: border-box;
  max-width: 640px;
  padding: 24px;
  width: 100%;
}

.faq {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.question {
  font-weight: bold;
  margin-bottom: 4px;
}

.answer {
  font-size: 14px;
}

.answer li {
  margin-bottom: 12px;
}

.purchaseAgreementDiv {
  margin-top: 16px;
  margin-bottom: 16px;
}

.ting {
  display: flex;
  justify-content: space-around;
  margin-top: 12px;
  padding: 36px;
  background-color: lightgrey;
  border: black solid 1px;
}
